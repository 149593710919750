@import '../styles/styles.scss';

section.meme-container {
	max-width: $maxWidth;
	margin: auto;

	h1 {
		color: $mainMDark;
		text-align: center;
		margin: 0.5em 0;
	}

	.canvas-container {
		canvas {
			width: 100%;
		}
	}

	input[type='text'] {
		margin: 1em;
	}

	input[type='file'] {
		display: none;
	}

	form {
		padding: 0 0.5em;

		input {
			cursor: pointer;
			outline: none;
		}

		input:focus,
		input:active {
			border: 2px solid $mainColor;
			background: rgba(0, 0, 0, 0.02);
		}

		fieldset {
			margin-bottom: 2em;
			border: 3px solid $mainColor;
			box-shadow: $card-shadow;
			background-color: white;
			border-radius: 8px;

			legend {
				@include font-size(22px, 38px);
				text-align: center;
				font-weight: bold;
				color: $mainColor;
			}

			.text-container {
				@include font-size(16px, 24px);
				margin-top: 1em;
				margin-left: 0.5em;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				flex-wrap: wrap;
				gap: 1em;

				// border: 1px solid red;

				.text {
					margin: 0.5em 0.5em;
				}

				.reset {
					margin: 0.4em 1em 1em 0.3em;
					background-color: rgba(255, 59, 48, 0.8);
				}

				input,
				select {
					@include font-size(14px, 24px);
					margin-left: 0.5em;
					margin-top: 1em;
					width: 90%;
					height: 2.2em;
					cursor: pointer;
					outline: none;
				}
				
				label{
					@include font-size(14px, 24px);
				}
				
			}
		}
	}

	.label-container {
		display: flex;
		text-align: center;
		margin-bottom: 1em;

		label {
			margin: auto;
			text-align: center;
			text-transform: uppercase;
			font-weight: bold;
		}
	}

	#button-container {
		span{
			@include font-size(18px, 28px);
		}
		
		background-color: $Light;
		margin: 2em;
	
		.btn__click, .image-data{
			display: block;
			text-align: center;
			margin: auto;
			margin-bottom: 1em;
		}

		a {
			@include font-size(18px, 28px);
			display: block;
			text-align: center;
			text-decoration: none;
			margin: auto;
		}

		.hidden {
			display: none;
		}
	}
}
