// font-size( minSize, maxSize)

body{
  
    h1{
        @include font-size(28px, 48px);
        font-weight: 700;
        line-height: 1.1em;
    }
    h2{
        @include font-size(22px, 38px);
        font-weight: 500;
        line-height: 1.2em;
    }
    h3{ 
        @include font-size(20px, 28px);
        font-weight: 400;
        line-height: 1.3em;
    }
    h4{
        @include font-size(18px, 24px);
        font-weight: 400;
        line-height: 1.4em;
    }
    p, h5, h6{
        @include font-size(16px, 21px);
        font-weight: 400;
        line-height: 1.5em;
    }
      
}
